import React from 'react'
import './base.css'
import Container from './container'
import Navigation from './navigation'

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <Container>
        <div> <Navigation /></div>
        <div style={{flex: '1 1 auto', display: 'flex', flexDirection: 'column'}}>{children}</div>
      </Container>
    )
  }
}

export default Template
